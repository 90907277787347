<template>
    <v-container class="mb-3">
        <v-layout column>
            <AdBTWR v-if="!user.subscribed"/>

            <User
                :key="$route.params.username"
                :username="$route.params.username"
                :authUser="user"
                @unauthorized="$emit('unauthorized')"
                v-on="$listeners"
            />
        </v-layout>
    </v-container>
</template>

<script>
    import User from '@/components/User.vue'
    import AdBTWR from '@/components/ads/BTWR.vue'
    import {useTitle} from '@vueuse/core'

    export default {
        props: ['user'],
        components: {
            User,
            AdBTWR
        },
        mounted(){
            useTitle(`User Profile: ${this.$route.params.username.toUpperCase()} - myGMRS.com`);
        }
    };
</script>
